<template>
  <div class="bx-footer__abo-teaser bx-form bx-typo--form">
    <div class="bx-footer__abo-teaser-image">
      <img :src="coverUrl"
           loading="lazy"
           width="2"
           height="3"
           data-pin-nopin="true"
           :alt="`Cover der aktuellen Ausgabe der ${abo[showMagazine].name}`"
           :title="`Cover der aktuellen Ausgabe der ${abo[showMagazine].name}`">
    </div>
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        {{ abo[showMagazine].name }} im Abo
      </span>
      <span class="bx-footer__abo-teaser-roofline">
        Erscheint {{ abo[showMagazine].frequence }}x im Jahr
      </span>
      <a class="bx-form__button"
         target="_blank"
         rel="noopener"
         :href="abo[showMagazine].link">
        Hier geht's zum Abo
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageStyle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      abo: {
        bravo: {
          link: 'https://www.bauer-plus.de/bravo-footer',
          frequence: '13',
          name: 'BRAVO'
        },
        'bravo-girl': {
          link: 'https://www.bauer-plus.de/bravo-girl-footer',
          frequence: '11',
          name: 'BRAVO GiRL!'
        },
        'bravo-sport': {
          link: 'https://www.bauer-plus.de/bravo-sport-footer',
          frequence: '13',
          name: 'BRAVO Sport'
        }
      }
    }
  },
  computed: {
    showMagazine () {
      return this.abo[this.pageStyle] ? this.pageStyle : 'bravo'
    },
    coverUrl () {
      return `${process.env.COVER_URL}${this.showMagazine.replace('-', '')}.jpg`
    }
  }
}
</script>
