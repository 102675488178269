<template>
  <ul class="bx-header__channel-navi bx-typo--header-channel-navi">
    <li :class="getStyleClasses({ name: 'bravo' })">
      <a :href="getAbsoluteUrl('/')">
        Bravo
      </a>
    </li>
    <li v-for="channel in menuChannels"
        :key="channel.index"
        :class="getStyleClasses(channel)">
      <a :href="channel.url"
         :target="channel.target">
        {{ channel.name }}
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'

export default {
  props: {
    menuChannels: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      channelMenuStyleMap: {
        bravo: 'bravo',
        'dr. sommer': 'dr-sommer',
        'bravo girl!': 'bravo-girl',
        'bravo sport': 'bravo-sport',
        'girl!': 'bravo-girl',
        sport: 'bravo-sport'
      }
    }
  },
  computed: {
    ...mapState(usePageStore, ['pageData'])
  },
  methods: {
    getStyleClasses (channel) {
      channel.name = channel.name || 'bravo'
      const activeStyle = this.pageData.pageMeta.style || 'bravo'
      return {
        'bx-header__channel-navi-item': true,
        'bx-js-active': activeStyle.toLowerCase() === this.channelMenuStyleMap[channel.name.toLowerCase()]
      }
    },
    getAbsoluteUrl (url) {
      if (import.meta.env.SSR) return url
      const a = document.createElement('a')
      a.href = url

      return a.href
    }
  }
}
</script>
